import React from 'react';
import * as Styled from './Component.styles';

import { Pink, Blue2, Blue1 } from '../helpers';

const Component = ({ name, children }) => (
  <div>
    <Styled.Component>
      <div aria-hidden="true">
        <Pink>const</Pink> <Blue1>{name}</Blue1> <Pink>=</Pink>{' '}
        <Blue1>()</Blue1> <Pink>=></Pink> (
      </div>
      <Styled.Content>{children}</Styled.Content>
      <div aria-hidden="true">
        )<Blue2>;</Blue2>
      </div>
    </Styled.Component>

    <div aria-hidden="true">
      <Blue2>export default</Blue2> {name}
      <Blue1>;</Blue1>
    </div>
  </div>
);

export default Component;
