import React from 'react';
import * as Styled from './Import.styles';
import { White, Green, Blue1 } from '../helpers';

const Import = ({ importString, path }) => (
  <Styled.Import aria-hidden={true}>
    import <White>{importString}</White> from <Green>'{path}'</Green>
    <Blue1>;</Blue1>
  </Styled.Import>
);

export default Import;
