import styled from 'styled-components';

export const HTMLContent = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
  }

  p,
  ul,
  li,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 1rem;

    &:first-child {
      margin-top: 0;
    }
  }

  li {
    list-style: circle;
    margin-left: 2rem;
  }
`;
