import styled from 'styled-components';

export const Bracket = styled.span<{ isInline?: boolean }>`
  display: ${({ isInline }) => (isInline ? 'inline' : 'block')};

  & + & {
    margin-top: 2rem;
  }
`;

export const Content = styled.div<{ isInline?: boolean }>`
  display: ${({ isInline }) => (isInline ? 'inline' : 'block')};

  padding-left: ${({ isInline }) => (isInline ? '0' : '2ch')};
`;

export const Attribute = styled.span`
  margin-left: 1ch;
`;
