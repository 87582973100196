import React from 'react';
import * as Styled from './Tag.styles';
import { Blue2, Red, Yellow, Green } from '../helpers';

type Props = {
  tag: any;
  displayName?: string;
  children?: any;
  attributes?: object;
  isInline?: boolean;
  newLineAttributes?: boolean;
};

const Attribute = ({ name, value, isNewLine }) => {
  const isString = typeof value === 'string';

  return (
    <Styled.Attribute as={isNewLine ? 'div' : 'span'}>
      <Yellow>{name}</Yellow>
      <Blue2>=</Blue2>
      <Green>
        {isString ? '"' : '{'}
        {value}
        {isString ? '"' : '}'}
      </Green>
    </Styled.Attribute>
  );
};

const Attributes = ({ attributes, isNewLine }) => (
  <>
    {Object.keys(attributes).reduce(
      (acc, name) => [
        ...acc,
        <Attribute
          key={name}
          name={name}
          value={attributes[name]}
          isNewLine={isNewLine}
        />,
      ],
      [],
    )}
  </>
);

const OpeningBracket = ({
  tag,
  isSelfClosing,
  attributes,
  isInline,
  newLineAttributes,
}) => (
  <Styled.Bracket aria-hidden={true} isInline={isInline}>
    <Blue2>{'<'}</Blue2>
    <Red>{tag}</Red>
    {attributes && (
      <Attributes attributes={attributes} isNewLine={newLineAttributes} />
    )}
    <Blue2>{isSelfClosing ? ' />' : '>'}</Blue2>
  </Styled.Bracket>
);

const ClosingBracket = ({ tag, isInline }) => (
  <Styled.Bracket aria-hidden={true} isInline={isInline}>
    <Blue2>{'</'}</Blue2>
    <Red>{tag}</Red>
    <Blue2>{'>'}</Blue2>
  </Styled.Bracket>
);

const Tag = ({
  tag,
  displayName = tag,
  children,
  attributes,
  isInline,
  newLineAttributes,
  ...props
}: Props) => {
  return (
    <>
      <OpeningBracket
        tag={displayName}
        isSelfClosing={!children}
        attributes={attributes}
        isInline={isInline}
        newLineAttributes={newLineAttributes}
      />

      {children && (
        <Styled.Content as={tag} isInline={isInline} {...props}>
          {children}
        </Styled.Content>
      )}

      {children && <ClosingBracket tag={displayName} isInline={isInline} />}
    </>
  );
};

export default Tag;
