import { graphql } from 'gatsby';
import React from 'react';
import Component from '../components/Component';
import Content, { HTMLContent } from '../components/Content';
import Import from '../components/Import';
import SEO from '../components/seo';
import Tag from '../components/Tag';

export const IndexPageTemplate = ({
  title,
  intro,
  contact,
  contentComponent,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <>
      <div style={{ marginBottom: '2rem' }}>
        <Import importString="React" path="react" />
      </div>

      <Component name="Index">
        <Tag tag="div">
          <Tag tag="h1">{title}</Tag>

          <Tag tag="div" displayName="p">
            <PostContent content={intro} />
          </Tag>

          {contact && contact.headline && (
            <Tag tag="h2">{contact.headline}</Tag>
          )}

          {contact && contact.links && (
            <Tag tag="ul">
              {contact.links.map(
                ({ link, text }, index) =>
                  link &&
                  text && (
                    <div
                      key={link}
                      style={{ marginTop: index !== 0 ? '2rem' : 0 }}
                    >
                      <Tag tag="li" isInline>
                        <a
                          href={link}
                          target={
                            link.startsWith('http') ? '_blank' : undefined
                          }
                          rel={
                            link.startsWith('http')
                              ? 'noopener noreferrer'
                              : undefined
                          }
                        >
                          {text}
                        </a>
                      </Tag>
                    </div>
                  ),
              )}
            </Tag>
          )}
        </Tag>
      </Component>
    </>
  );
};

const IndexPage = ({ data }) => {
  const { markdownRemark: page } = data;

  return (
    <>
      <SEO title={'Startseite'} />

      <IndexPageTemplate
        title={page.frontmatter.title}
        intro={page.html}
        contact={page.frontmatter.contact}
        contentComponent={HTMLContent}
      />
    </>
  );
};

export default IndexPage;

export const testPageQuery = graphql`
  query IndexPage {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      html
      frontmatter {
        title
        contact {
          headline
          links {
            text
            link
          }
        }
      }
    }
  }
`;
