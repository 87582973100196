import React from 'react';
import * as Styled from './Content.styles';

export const HTMLContent = ({
  content,
  className,
}: {
  content: any;
  className?: string;
}) => (
  <Styled.HTMLContent
    className={className}
    dangerouslySetInnerHTML={{ __html: content }}
  />
);

const Content = ({ content, className }) => (
  <Styled.HTMLContent className={className}>{content}</Styled.HTMLContent>
);

export default Content;
