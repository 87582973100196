import styled from 'styled-components';

export const White = styled.span`
  color: #fff;
`;

export const Pink = styled.span`
  color: #b06698;
`;

export const Red = styled.span`
  color: #e26d69;
`;

export const Blue1 = styled.span`
  color: #7eaac7;
`;

export const Blue2 = styled.span`
  color: #6a8799;
`;

export const Green = styled.span`
  color: #909d63;
`;

export const Yellow = styled.span`
  color: #ebc17a;
`;
